import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { toAbsoluteUrl } from '../../helpers'

const LOADING = toAbsoluteUrl('/assets/img/loading.svg')
const NO_IMAGE = toAbsoluteUrl('/assets/img/no-image.svg')


const RenderSrcImg = ({ imageURL, ...props }) => {
  const [state, setState] = useState({ isLoading: true, data: null })
  const source = axios.CancelToken.source();

  useEffect(() => {
    if (imageURL){
      axios
        .get(imageURL, {
          cancelToken: source.token,
          responseType: 'arraybuffer',
          headers: {
            'Cache-Control': 'no-cache',
            'Pragma': 'no-cache',
            'Expires': '0',
          }
        })
        .then(response => {
          const base64 = btoa(
            new Uint8Array(response.data).reduce(
              (data, byte) => data + String.fromCharCode(byte),
              '',
            ),
          );
          setState({ isLoading: false, data:  "data:;base64," + base64 })
        })
        .catch(() => setState({ isLoading: false, data: null }))
    }else {
      setState({ isLoading: false, data: null })
    }

    // eslint-disable-next-line
  }, [imageURL])

  useEffect(() => {
    return () => source.cancel("Landing Component got unmounted");
    // eslint-disable-next-line
  }, [])

  if (state.isLoading){
    return <img src={LOADING} {...props} alt='...' />
  }else if (state.data){
    return <img src={state.data} {...props} alt='...' />
  }else {
    return <img src={NO_IMAGE} {...props} alt='...' />
  }
}

export default RenderSrcImg
