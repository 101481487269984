export * from './aboutUsHelper'
export * from './homeHelper'

export const ERROR_CODES = require('./errorsHelper')


export const AUTO_CLOSE = 1000
export const VERSION = "v0.1"

export const SITE_NAME = "3 coeurs"
