import React from "react";
import { Link } from "react-router-dom";
import {homeRoute, productsRoute} from "../../../routes";
import FormattedMessage from "../../common/FormattedMessage";

const MobileNavMenu = () => {
  return (
    <nav className="offcanvas-navigation" id="offcanvas-navigation">
      <ul>
        <li className="menu-item-has-children">
          <ul className="sub-menu">
            <li className='nav-item'>
              <Link to={homeRoute.path} className='nav-link'>
                <FormattedMessage id='MENU.HOME' />
              </Link>
            </li>
            <li className='nav-item'>
              <Link to={productsRoute.path} className='nav-link'>
                <FormattedMessage id='MENU.SHOP' />
              </Link>
            </li>
          </ul>
        </li>
      </ul>
    </nav>
  );
};

export default MobileNavMenu
