import { lazy } from 'react'

const MyCart = lazy(() =>  import('./../../pages/my-cart/MyCart'))
const Wishlist = lazy(() =>  import('./../../pages/my-cart/Wishlist'))
const Compare = lazy(() =>  import('./../../pages/my-cart/Compare'))
const Checkout = lazy(() =>  import('./../../pages/my-cart/Checkout'))


export const myCartRoute = {
  path: '/my-cart',
  component: MyCart
}

export const wishlistRoute = {
  path: '/wishlist',
  component: Wishlist
}

export const compareRoute = {
  path: '/compare',
  component: Compare
}

export const checkoutRoute = {
  path: '/checkout',
  component: Checkout
}
