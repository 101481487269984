import {combineReducers} from 'redux'

// thrid party lib reducers
import { reducer as formReducer } from 'redux-form'

// app reducers
import authenticationReducer from './authenticationReducer'
import contactReducer from './contactReducer'
//import cartReducer from './cartReducer'
//import productReducer from "./productReducer";
import eventReducer from "./eventReducer";
import userReducer from './userReduer'

import productReducer from "./productReducer";
import cartReducer from "./cartReducer";
import wishlistReducer from "./wishlistReducer";
import compareReducer from "./compareReducer";

import { createMultilanguageReducer } from "redux-multilanguage";

// combine app reducers
export default combineReducers({
  form: formReducer,

  authentication: authenticationReducer,
  contact: contactReducer,
  user: userReducer,
  cart: cartReducer,
  product: productReducer,
  event : eventReducer,

  multilanguage: createMultilanguageReducer({ currentLanguageCode: "en" }),
  wishlist: wishlistReducer,
  compare: compareReducer
})
