import {toAbsoluteUrl} from "../helpers";
import {aboutUsRoute, productsRoute, /*productsRoute, /* eventsRoute, /*homeRoute,bookingRoute */} from "../routes";  


export const CATEGORIES = [
  {
    id: 1,
    image: "/assets/img/icon-img/support-1.jpg",
    title: "HOME.FEATURE_FIRST.TITLE",
    subtitle: "HOME.FEATURE_FIRST.SUB_TITLE",
    link: productsRoute.path
  },

  {
    id: 3,
    image: "/assets/img/icon-img/support-3.jpg",
    title: "HOME.FEATURE_THIRD.TITLE",
    subtitle: "HOME.FEATURE_THIRD.SUB_TITLE",
    link: productsRoute.path
  },
  /*{
    id: 4,
    image: "/assets/img/icon-img/support-4.png",
    title: "HOME.FEATURE_FORTH.TITLE",
    subtitle: "HOME.FEATURE_FORTH.SUB_TITLE"
  }*/
];

export const PARTNERS = [
  {
    url: "/#",
    logo: toAbsoluteUrl("/media/images/partner/partner1.png")
  },
  {
    url: "/#",
    logo: toAbsoluteUrl("/media/images/partner/partner2.png")
  },
  {
    url: "/#",
    logo: toAbsoluteUrl("/media/images/partner/partner3.png")
  },
  {
    url: "/#",
    logo: toAbsoluteUrl("/media/images/partner/partner4.png")
  },
  {
    url: "/#",
    logo: toAbsoluteUrl("/media/images/partner/partner5.png")
  },
  {
    url: "/#",
    logo: toAbsoluteUrl("/media/images/partner/partner6.png")
  }
]

export const HOME_ABOUT_US = {
  text: "WELECOM.TITLE",
  desc: "WELECOM.DESC",
  button: "BUTTON.DISCOVER",
  image: toAbsoluteUrl("/media/images/offer/offer1.png"),
  path: aboutUsRoute.path
}


export const HOME_BANNERS = [
  {
    id: 1,
    title: "SHOPPING.TITLE",
    subtitle: "SHOPPING.DESC",
    image: "/assets/img/slider/single-slide-2.png",
    url: productsRoute.path
  },
  {
    id: 2,
    title: "SHOPPING.TITLE",
    subtitle: "SHOPPING.DESC",    
    image: "/assets/img/slider/single-slide-1.png",
    url: productsRoute.path
  }
];


