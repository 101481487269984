import { ACTIONS, ENDPOINTS, CALL_API, HTTP_METHODS } from './../constants'

export const login = (payload) =>
  ({
    type: CALL_API,
    payload,
    meta: {
      actions: {
        init: ACTIONS.LOGIN_INIT,
        success: ACTIONS.LOGIN_SUCCEDED,
        fail: ACTIONS.LOGIN_FAILED
      },
      endpoint: ENDPOINTS.LOGIN,
      method: HTTP_METHODS.POST
    }
  })
export const loginGoogle = (payload) =>
  ({
    type: CALL_API,
    payload,
    meta: {
      actions: {
        init: ACTIONS.LOGINGOOGLE_INIT,
        success: ACTIONS.LOGINGOOGLE_SUCCEDED,
        fail: ACTIONS.LOGINGOOGLE_FAILED
      },
      endpoint: ENDPOINTS.LOGINGOOGLE,
      method: HTTP_METHODS.POST
    }
  })

export const loginFacebook = (payload) =>
  ({
    type: CALL_API,
    payload,
    meta: {
      actions: {
        init: ACTIONS.LOGINFACEBOOK_INIT,
        success: ACTIONS.LOGINFACEBOOK_SUCCEDED,
        fail: ACTIONS.LOGINFACEBOOK_FAILED
      },
      endpoint: ENDPOINTS.LOGINFACEBOOK,
      method: HTTP_METHODS.POST
    }
  })

export const signup = (payload) =>
  ({
    type: CALL_API,
    payload,
    meta: {
      actions: {
        init: ACTIONS.SIGNUP_INIT,
        success: ACTIONS.SIGNUP_SUCCEDED,
        fail: ACTIONS.SIGNUP_FAILED
      },
      endpoint: ENDPOINTS.REGISTER,
      method: HTTP_METHODS.POST
    }
  })

export const signupGoogle = (payload) => ({
  type: ACTIONS.SIGNUP_GOOGLE,
  payload: {
    fullName: payload.profileObj.name,
    email: payload.profileObj.email,
    socialNetworks: {
      id: payload.profileObj.googleId,
      accessToken: payload.tokenObj.access_token,
      provider: 'google ',
      fullName: payload.profileObj.name,
      firstName: payload.profileObj.givenName,
      lastName: payload.profileObj.familyName,
      email: payload.profileObj.email,
      phoneNumber: '00000000',
      avatar: payload.profileObj.imageUrl,
      birthday: 'birthday',
      gender: 'gender',
      location: 'location',
      profileUrl: 'profileUrl',
      ageRange: 'ageRange'
    }
  }
})

export const signupFacebook = (payload) => ({
  type: ACTIONS.SIGNUP_FACEBOOK,
  payload: {
    fullName: payload.profileObj.first_name + ' '  + payload.profileObj.last_name,
    email: payload.profileObj.email,
    socialNetworks: {
      id: payload.profileObj.id,
      accessToken: payload.profileObj.accessToken,
      provider: "facebook ",
      fullName: payload.profileObj.first_name + payload.profileObj.last_name,
      firstName: payload.profileObj.first_name,
      lastName: payload.profileObj.last_name,
      email: payload.profileObj.email,
      phoneNumber: "00000000",
      avatar: payload.profileObj.imageUrl,
      birthday: "birthday",
      gender: "gender",
      location: "location",
      profileUrl: "profileUrl",
      ageRange: "ageRange",
    },
  },
});


export const signupThirdParty = (payload) =>
  ({
    type: CALL_API,
    payload,
    meta: {
      actions: {
        init: ACTIONS.SIGNUP_THIRD_PARTY_INIT,
        success: ACTIONS.SIGNUP_THIRD_PARTY_SUCCEDED,
        fail: ACTIONS.SIGNUP_THIRD_PARTY_FAILED
      },
      endpoint: ENDPOINTS.SIGNUP_THIRD_PARTY,
      method: HTTP_METHODS.POST
    }
  })


export const forgotPassword = (payload) =>
  ({
    type: CALL_API,
    payload,
    meta: {
      actions: {
        init: ACTIONS.FORGOT_PASSWORD_INIT,
        success: ACTIONS.FORGOT_PASSWORD_SUCCEDED,
        fail: ACTIONS.FORGOT_PASSWORD_FAILED
      },
      endpoint: ENDPOINTS.FORGOT_PASSWORD,
      method: HTTP_METHODS.POST
    }
  })

export const fetchToken = ({ token }) =>
  ({
    type: CALL_API,
    meta: {
      actions: {
        init: ACTIONS.FETCH_TOKEN_INIT,
        success: ACTIONS.FETCH_TOKEN_SUCCEDED,
        fail: ACTIONS.FETCH_TOKEN_FAILED
      },
      endpoint: ENDPOINTS.FETCH_TOKEN.replace(':param', token),
      method: HTTP_METHODS.GET
    }
  })

export const resetPassword = (payload) =>
  ({
    type: CALL_API,
    payload,
    meta: {
      actions: {
        init: ACTIONS.RESET_PASSWORD_INIT,
        success: ACTIONS.RESET_PASSWORD_SUCCEDED,
        fail: ACTIONS.RESET_PASSWORD_FAILED
      },
      endpoint: ENDPOINTS.RESET_PASSWORD,
      method: HTTP_METHODS.POST
    }
  })

export const logout = () =>
  ({
    type: ACTIONS.LOGOUT_SUCCEDED
  })
