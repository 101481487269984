import { lazy } from 'react'

const MyAccount = lazy(() =>  import('./../../pages/my-account/MyAccount'))
const MyOrder = lazy(() => import("./../../pages/my-account/MyOrders"));


export const myAccountRoute = {
  path: '/my-account',
  component: MyAccount
}

export const myOrderRoute = {
  path: '/my-order',
  component: MyOrder
}

