import { ACTIONS } from "./../constants"

// add to wishlist
export const addToWishlist = (item) => ({ type: ACTIONS.ADD_TO_WISHLIST, payload: item })

// delete from wishlist
export const deleteFromWishlist = (item) => ({ type: ACTIONS.DELETE_FROM_WISHLIST, payload: item })
  
//delete all from wishlist
export const deleteAllFromWishlist = () => ({ type: ACTIONS.DELETE_ALL_FROM_WISHLIST })
