import { ACTIONS } from "./../constants";
import { uniqBy } from "lodash";

const initialState = {
  products: [],
  newProducts: [],
  discountedProducts: [],
  categories: [],
  colors: [],
  brands: [],
  sizes: [],
  searchTerm: "",
  hasMore: true,
  product: null,
  totalItems: 0,
  totalPages: 0,
  currentPage: 0,
  isFetching: false,
  isLoading: false,
  success: false,
  error: null,
  relatedProducts: [],
};

export default (state = initialState, action) => {
  const { payload, type } = action;

  switch (type) {
    case ACTIONS.CLEAR_ERROR: {
      return { ...state, success: false, error: null };
    }

    case ACTIONS.FETCH_PRODUCTS_INIT: {
      return { ...state, isFetching: true, error: null };
    }
    case ACTIONS.FETCH_PRODUCTS_SUCCEDED: {
      const {
        products = [],
        totalCount: totalItems = 0,
        totalPages = 1,
      } = payload;
      return {
        ...state,
        isFetching: false,
        hasMore: products.length === 10,
        products: products.map((prod) => ({
          ...prod,
          stock : prod.variants.reduce((acc, v) => acc + v.quantity, 0),
          variation: prod.availableColors
            .filter(
              (color) =>
                prod.variants
                  .filter(
                    (element) =>
                      element.color.title == color.title && element.quantity > 0
                  )
                  .map((variant) => variant.quantity)
                  .reduce((a, b) => a + b, 0) > 0
            )
            .map((colorObj) => ({
              color: colorObj.value,
              colorLabel: colorObj.title,
              size: prod.variants
                .filter(
                  (element) =>
                    element.color.title == colorObj.title &&
                    element.quantity > 0
                )
                .map((variant) => ({
                  name: variant.size.title,
                  stock: variant.quantity,
                })),
            })),
        })),
        totalItems,
        currentPage:
          products.length > 0 ? state.currentPage + 1 : state.currentPage,
        totalPages,
      };
    }
    case ACTIONS.FETCH_PRODUCTS_FAILED: {
      return { ...state, isFetching: false, hasMore: false, error: payload };
    }

    //related products
    case ACTIONS.FETCH_RELATEDPRODUCT_INIT: {
      return { ...state, isFetching: true, error: null };
    }
    case ACTIONS.FETCH_RELATEDPRODUCT_SUCCEDED: {
      const stock =
        payload.availableColors.length > 0 && payload.availableSizes.length > 0
          ? 20
          : 0;
      const variation = payload.availableColors
        .filter(
          (color) =>
            payload.variants
              .filter(
                (element) =>
                  element.color.title == color.title && element.quantity > 0
              )
              .map((variant) => variant.quantity)
              .reduce((a, b) => a + b, 0) > 0
        )
        .map((colorObj) => ({
          color: colorObj.value,
          colorLabel: colorObj.title,
          size: payload.variants
            .filter(
              (element) =>
                element.color.title == colorObj.title && element.quantity > 0
            )
            .map((variant) => ({
              name: variant.size.title,
              stock: variant.quantity,
            })),
        }));
      const product = { ...payload, variation, stock };
      const allProducts = uniqBy([...state.relatedProducts, product], "id");
      return { ...state, isFetching: false, relatedProducts: allProducts };
    }
    case ACTIONS.FETCH_RELATEDPRODUCT_FAILED: {
      return { ...state, isFetching: false, hasMore: false, error: payload };
    }

    case ACTIONS.ADD_TO_CART: {
      const { quantity, selectedProductColor, selectedProductSize } = payload
      const size = selectedProductColor.size.find((item) => item.name === selectedProductSize)
      
      if (state.product && state.product.variation) {
        const product = { ...state.product, variation: state.product.variation.map((variant) => ({ ...variant, size: variant.size.map((size) => selectedProductSize !== size.name ? size : ({ ...size, stock: (size.stock - quantity)}))}))}
        return { ...state, product }
      }

      return state
    }

    case ACTIONS.FETCH_PRODUCT_INIT: {
      return { ...state, isFetching: true, error: null, relatedProducts: [] , product : null };
    }
    case ACTIONS.FETCH_PRODUCT_SUCCEDED: {
      const stock =
        payload.variants
          .map((variant) => variant.quantity)
          .reduce((a, b) => a + b, 0);

      const variation = payload.availableColors
        .filter(
          (color) =>
            payload.variants
              .filter(
                (element) =>
                  element.color.title == color.title && element.quantity > 0
              )
              .map((variant) => variant.quantity)
              .reduce((a, b) => a + b, 0) > 0
        )
        .map((colorObj) => ({
          color: colorObj.value,
          colorLabel: colorObj.title,
          size: payload.variants
            .filter(
              (element) =>
                element.color.title == colorObj.title && element.quantity > 0
            )
            .map((variant) => ({
              name: variant.size.title,
              stock: variant.quantity,
            })),
        }));
      return {
        ...state,
        isFetching: false,
        product: { ...payload, variation, stock },
      };
    }
    case ACTIONS.FETCH_PRODUCT_FAILED: {
      return { ...state, isFetching: false, error: payload };
    }

    case ACTIONS.FETCH_IN_DISCOUNT_PRODUCTS_INIT: {
      return { ...state, isFetching: true, error: null };
    }
    case ACTIONS.FETCH_IN_DISCOUNT_PRODUCTS_SUCCEDED: {
      const { products = [] } = payload;
      return {
        ...state,
        isFetching: false,
        discountedProducts:  products.map((prod) => ({
          ...prod,
          stock : prod.variants.reduce((acc, v) => acc + v.quantity, 0),
          variation: prod.availableColors
            .filter(
              (color) =>
                prod.variants
                  .filter(
                    (element) =>
                      element.color.title == color.title && element.quantity > 0
                  )
                  .map((variant) => variant.quantity)
                  .reduce((a, b) => a + b, 0) > 0
            )
            .map((colorObj) => ({
              color: colorObj.value,
              colorLabel: colorObj.title,
              size: prod.variants
                .filter(
                  (element) =>
                    element.color.title == colorObj.title &&
                    element.quantity > 0
                )
                .map((variant) => ({
                  name: variant.size.title,
                  stock: variant.quantity,
                })),
            })),
        })),
      };
    }
    case ACTIONS.FETCH_IN_DISCOUNT_PRODUCTS_FAILED: {
      return { ...state, isFetching: false, error: payload };
    }

    case ACTIONS.FETCH_NEW_PRODUCTS_INIT: {
      return { ...state, isFetching: true, error: null };
    }
    case ACTIONS.FETCH_NEW_PRODUCTS_SUCCEDED: {
      const { products = [] } = payload;
      return {
        ...state,
        isFetching: false,
        newProducts: products.map((prod) => ({
          ...prod,
          stock : prod.variants.reduce((acc, v) => acc + v.quantity, 0),
          variation: prod.availableColors
            .filter(
              (color) =>
                prod.variants
                  .filter(
                    (element) =>
                      element.color.title == color.title && element.quantity > 0
                  )
                  .map((variant) => variant.quantity)
                  .reduce((a, b) => a + b, 0) > 0
            )
            .map((colorObj) => ({
              color: colorObj.value,
              colorLabel: colorObj.title,
              size: prod.variants
                .filter(
                  (element) =>
                    element.color.title == colorObj.title &&
                    element.quantity > 0
                )
                .map((variant) => ({
                  name: variant.size.title,
                  stock: variant.quantity,
                })),
            })),
        })),
      };
    }
    case ACTIONS.FETCH_NEW_PRODUCTS_FAILED: {
      return { ...state, isFetching: false, error: payload };
    }

    case ACTIONS.FETCH_CATEGORIES_INIT: {
      return { ...state, error: null };
    }
    case ACTIONS.FETCH_CATEGORIES_SUCCEDED: {
      return { ...state, categories: payload ?? [] };
    }
    case ACTIONS.FETCH_CATEGORIES_FAILED: {
      return { ...state, error: payload };
    }

    case ACTIONS.FETCH_COLORS_INIT: {
      return { ...state, error: null };
    }
    case ACTIONS.FETCH_COLORS_SUCCEDED: {
      return { ...state, colors: payload?.colors ?? [] };
    }
    case ACTIONS.FETCH_COLORS_FAILED: {
      return { ...state, error: payload };
    }

    case ACTIONS.FETCH_SIZES_INIT: {
      return { ...state, error: null };
    }
    case ACTIONS.FETCH_SIZES_SUCCEDED: {
      return { ...state, sizes: payload?.size ?? [] };
    }
    case ACTIONS.FETCH_SIZES_FAILED: {
      return { ...state, error: payload };
    }

    case ACTIONS.FETCH_BRANDS_INIT: {
      return { ...state, error: null };
    }
    case ACTIONS.FETCH_BRANDS_SUCCEDED: {
      return { ...state, brands: payload?.metaData[0]?.details ?? [] };
    }
    case ACTIONS.FETCH_BRANDS_FAILED: {
      return { ...state, error: payload };
    }

    case ACTIONS.FILTER_PRODUCTS: {
      return { ...state, searchTerm: payload, hasMore: true };
    }

    case ACTIONS.CHECKOUT_ORDER_INIT: {
      return { ...state, isFetching: true, success: false, error: null };
    }
    case ACTIONS.CHECKOUT_ORDER_SUCCEDED: {
      return { ...state, isFetching: false, success: true };
    }
    case ACTIONS.CHECKOUT_ORDER_FAILED: {
      return { ...state, isFetching: false, success: false, error: payload };
    }

    default: {
      return state;
    }
  }
};
