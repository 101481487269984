import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'
import { multilanguage } from 'redux-multilanguage'
import { aboutUsRoute, contactUsRoute, homeRoute, productsRoute } from '../../routes'
import FormattedMessage from '../common/FormattedMessage'

const NavMenu = ({ menuWhiteClass, sidebarMenu }) => {
  return (
    <div
      className={` ${
        sidebarMenu
          ? 'sidebar-menu'
          : `main-menu ${menuWhiteClass || ''}`
      } `}
    >
      <nav>
        <ul>
          <li className='nav-item'>
            <Link to={homeRoute.path} className='nav-link'>
              <FormattedMessage id='MENU.HOME' />
            </Link>
          </li>

          <li className='nav-item'>
            <Link to={productsRoute.path} className='nav-link'>
              <FormattedMessage id='MENU.SHOP' />
            </Link>
          </li>

          {/* <li className="nav-item">
                      <Link to={eventsRoute.path} className="nav-link">
                        <FormattedMessage id="MENU.EVENT" />
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link to={bookingRoute.path} className="nav-link">
                        <FormattedMessage id="MENU.BOOKING" />
                      </Link>
                    </li> */}

          <li className='nav-item'>
            <Link to={aboutUsRoute.path} className='nav-link'>
              <FormattedMessage id='MENU.ABOUT_US' />
            </Link>
          </li>

          <li className='nav-item'>
            <Link to={contactUsRoute.path} className='nav-link'>
              <FormattedMessage id='MENU.CONTACT_US' />
            </Link>
          </li>
        </ul>
      </nav>
    </div>
  )
}

NavMenu.propTypes = {
  menuWhiteClass: PropTypes.string,
  sidebarMenu: PropTypes.bool,
  strings: PropTypes.object
}

export default multilanguage(NavMenu)
