import PropTypes from "prop-types";
import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import {getProductURL} from "../../../helpers";
import { formatPrice, getDiscountPrice } from "../../../helpers/product";
import {checkoutRoute, myCartRoute, productRoute} from "../../../routes";
import FormattedMessage from "../../common/FormattedMessage";
import RenderSrcImg from "../../common/RenderSrcImg";

const MenuCart = ({ cartData, forwardRef, closeMenuCart, deleteFromCart }) => {
  let cartTotalPrice = 0;
  const { addToast } = useToasts();
  return (
    <div ref={forwardRef} className="shopping-cart-content">
      {cartData && cartData.length > 0 ? (
        <Fragment>
          <ul>
            {cartData.map((single, key) => {
              const discountedPrice = getDiscountPrice(
                single.product.price,
                single.product.discount
              );
              const finalProductPrice = formatPrice(single.product.price);
              const finalDiscountedPrice = formatPrice(discountedPrice);

              discountedPrice != null
                ? (cartTotalPrice += finalDiscountedPrice * single.quantity)
                : (cartTotalPrice += finalProductPrice * single.quantity);

              return (
                <li className="single-shopping-cart" key={key}>
                  <div className="shopping-cart-img">
                    <Link  onClick={closeMenuCart} to={productRoute.path.replace(":param", single.product.id)}>
                      <RenderSrcImg height="500" width="500" imageURL={getProductURL(single.product.backgroundImageUrl)} className="img-fluid" />
                    </Link>
                  </div>
                  <div className="shopping-cart-title">
                    <h4>
                      <Link  onClick={closeMenuCart} to={productRoute.path.replace(":param", single.product.id)}>
                        {" "}
                        {single.product.title}{" "}
                      </Link>
                    </h4>
                    {single.selectedProductColor &&
                        single.selectedProductSize ? (
                          <div className="cart-item-variation">
                            <div>
                              <span className="product-selected"> <FormattedMessage id="COLOR" /> </span>
                              <span className="product-color" style={{ backgroundColor: single.selectedProductColor.color }}></span>
                            </div>
                            <div>
                              <span className="product-selected"><FormattedMessage id="SIZE" /> </span>
                              <span className="product-size"> {single.selectedProductSize}</span>
                            </div>
                          </div>
                        ) : (
                      ""
                    )}
                    <span className="product-price text-bold">
                      { single.quantity } * {formatPrice(discountedPrice !== null ? finalDiscountedPrice : finalProductPrice)} <FormattedMessage id="CURRENCY" />
                    </span>
                  </div>
                  <div className="shopping-cart-delete">
                    <button onClick={() => deleteFromCart(single, addToast)}>
                      <i className="fa fa-times-circle" />
                    </button>
                  </div>
                </li>
              );
            })}
          </ul>
          <div className="shopping-cart-total">
            <h4>
              <FormattedMessage id="CART.TOTAL_PRICE" />{" "}
              <span className="shop-total">
                { formatPrice(cartTotalPrice) } <FormattedMessage id="CURRENCY" />
              </span>
            </h4>
          </div>
          <div className="shopping-cart-btn btn-hover text-center">
            <Link onClick={closeMenuCart} className="default-btn" to={myCartRoute.path}>
              <FormattedMessage id="CART.TITLE" />
            </Link>
            <Link onClick={closeMenuCart}
              className="default-btn"
              to={checkoutRoute.path}
            >
              <FormattedMessage id="CHECKOUT.TITLE" />
            </Link>
          </div>
        </Fragment>
      ) : (
        <p className="text-center"><FormattedMessage id="NO_ITEMS" /></p>
      )}
    </div>
  );
};

MenuCart.propTypes = {
  cartData: PropTypes.array,
  deleteFromCart: PropTypes.func
};

export default MenuCart;
