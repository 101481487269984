export const ACTIONS = require('./actionsConstant')
export const ENDPOINTS = require('./endpointsConstant')

export const API_BASE_URL = window._ENV_.REACT_APP_API_BASE_URL
export const MAP_BOX_API_ACCESS_TOKEN = window._ENV_.REACT_APP_MAP_BOX_API_ACCESS_TOKEN

export const CALL_API = 'middleware@CALL_API'

export const HTTP_METHODS = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  PATCH: 'PATCH',
  DELETE: 'DELETE'
}

export const VARIATION = {
  variation: [
    {
      color: 'white',
      image: '/assets/img/product/fashion/1.jpg',
      size: [
        {
          name: 'x',
          stock: 3
        },
        {
          name: 'm',
          stock: 2
        },
        {
          name: 'xl',
          stock: 5
        }
      ]
    },
    {
      color: 'black',
      image: '/assets/img/product/fashion/8.jpg',
      size: [
        {
          name: 'x',
          stock: 4
        },
        {
          name: 'm',
          stock: 7
        },
        {
          name: 'xl',
          stock: 9
        },
        {
          name: 'xxl',
          stock: 1
        }
      ]
    },
    {
      color: 'brown',
      image: '/assets/img/product/fashion/3.jpg',
      size: [
        {
          name: 'x',
          stock: 1
        },
        {
          name: 'm',
          stock: 2
        },
        {
          name: 'xl',
          stock: 4
        },
        {
          name: 'xxl',
          stock: 0
        }
      ]
    }
  ]
}
