export const PHONE = "+216 20 588 348";
export const ADDRESS1 = " Tunis City - Boutique N°40 porte B1 . Géant";
export const ADDRESS2 = " LA MARSA - Boutique au centre commercial MILLENIUM, Route de la marsa ";
export const EMAIL = "contact@les3coeurs-lingeriefine.com";

export const FB_LINK = "https://www.facebook.com/lestroiscoeurs";
export const INSTAGRAM_LINK = "https://www.instagram.com/les3coeurs/?hl=en";
export const TWITTER_LINK = "https://www.facebook.com/SmokyBuns.tn/"

export const RECIVER_MAIL = "contact@les3coeurs-lingeriefine.com";


export const BANNER_DATA = [
  {
    id: 1,
    image: "/assets/img/banner/banner-1.jpg",
    title: "ABOUT_US.OUR_STORY.TITLE",
  },
  {
    id: 2,
    image: "/assets/img/banner/banner-2.jpg",
    title: "ABOUT_US.OUR_VALUES.TITLE",
  },
  {
    id: 3,
    image: "/assets/img/banner/banner-3.jpg",
    title: "ABOUT_US.OUR_PROMISE.TITLE",
  }
]


export const BRAND_LOGO_DATA = [
  {
    "id": "1",
    "image": "/assets/img/brand-logo/brand-logo-1.png"
  },
  {
    "id": "2",
    "image": "/assets/img/brand-logo/brand-logo-2.png"
  },
  {
    "id": "3",
    "image": "/assets/img/brand-logo/brand-logo-3.png"
  },
  {
    "id": "4",
    "image": "/assets/img/brand-logo/brand-logo-4.png"
  },
  {
    "id": "5",
    "image": "/assets/img/brand-logo/brand-logo-5.png"
  },
  {
    "id": "6",
    "image": "/assets/img/brand-logo/brand-logo-6.png"
  },
  {
    "id": "7",
    "image": "/assets/img/brand-logo/brand-logo-7.png"
  },
  {
    "id": "8",
    "image": "/assets/img/brand-logo/brand-logo-8.png"
  },
  {
    "id": "9",
    "image": "/assets/img/brand-logo/brand-logo-9.png"
  },
  {
    "id": "10",
    "image": "/assets/img/brand-logo/brand-logo-10.png"
  }
]


export const FUN_FACT_DATA = [
  {
    id: 1,
    iconClass: "pe-7s-portfolio",
    countNum: 360,
    title: "ABOUT_US.FUN_FACT_FIRST"
  },
  {
    id: 2,
    iconClass: "pe-7s-cup",
    countNum: 690,
    title: "ABOUT_US.FUN_FACT_SECOND"
  },
  {
    id: "3",
    iconClass: "pe-7s-light",
    countNum: 100,
    title: "ABOUT_US.FUN_FACT_THIRD"
  },
  {
    id: "4",
    iconClass: "pe-7s-smile",
    countNum: 420,
    title: "ABOUT_US.FUN_FACT_FORTH"
  }
]


export const TEXT_GRID_DATA = [
  {
    id: 1,
    title: "ABOUT_US.OUR_STORY.TITLE",
    desc: "ABOUT_US.OUR_STORY.DESC",
    text: [
      "ABOUT_US.OUR_STORY.FIRST",
      "ABOUT_US.OUR_STORY.SECOND",
      "ABOUT_US.OUR_STORY.THIRD",
    ],
    desc_expand: "ABOUT_US.OUR_STORY.DESC_EXPAND",
  },
  {
    id: 2,
    title: "ABOUT_US.OUR_VALUES.TITLE",
    desc: "ABOUT_US.OUR_VALUES.DESC",
    text: [
      "ABOUT_US.OUR_VALUES.FIRST",
      "ABOUT_US.OUR_VALUES.SECOND",
      "ABOUT_US.OUR_VALUES.THIRD",
    ],
    //desc_expand: "ABOUT_US.OUR_VALUES.DESC_EXPAND",
  },
  {
    id: 3,
    title: "ABOUT_US.OUR_PROMISE.TITLE",
    desc: "ABOUT_US.OUR_PROMISE.DESC",
    text: [
      "ABOUT_US.OUR_PROMISE.FIRST",
      "ABOUT_US.OUR_PROMISE.SECOND",
      "ABOUT_US.OUR_PROMISE.THIRD",
    ],
    //desc_expand: "ABOUT_US.OUR_PROMISE.DESC_EXPAND",
  },
];
