import { ACTIONS } from "./../constants"


const initialState = {
  user: null,
  orders: [],
  bookings: [],
  isFetching: false,
  isLoading: false,
  success: false,
  error: null,
};


export default (state = initialState, action) => {
  
  const { payload, type } = action
  
  switch (type) {
    case ACTIONS.CLEAR_ERROR: {
      return { ...state, success: false, error: null };
    }

    case ACTIONS.FETCH_USER_INFORMATION_INIT: {
      return { ...state, isFetching: true, error: null };
    }
    case ACTIONS.FETCH_USER_INFORMATION_SUCCEDED: {
      return { ...state, isFetching: false, user: payload };
    }
    case ACTIONS.FETCH_USER_INFORMATION_FAILED: {
      return { ...state, isFetching: false, error: payload };
    }

    case ACTIONS.FETCH_USER_ORDERS_INIT: {
      return { ...state, isFetching: true, error: null };
    }
    case ACTIONS.FETCH_USER_ORDERS_SUCCEDED: {
      return { ...state, isFetching: false, orders: payload };
    }
    case ACTIONS.FETCH_USER_ORDERS_FAILED: {
      return { ...state, isFetching: false, error: payload };
    }

    case ACTIONS.UPDATE_USER_INFORMATION_INIT: {
      return { ...state, isFetching: true, success: false, error: null };
    }
    case ACTIONS.UPDATE_USER_INFORMATION_SUCCEDED: {
      return { ...state, isFetching: false, success: true, user: payload };
    }
    case ACTIONS.UPDATE_USER_INFORMATION_FAILED: {
      return { ...state, isFetching: false, success: false, error: payload };
    }

    //booking
    case ACTIONS.FETCH_USER_BOOKINGS_INIT: {
      return { ...state, isFetching: true, error: null };
    }
    case ACTIONS.FETCH_USER_BOOKINGS_SUCCEDED: {
      return { ...state, isFetching: false, bookings: payload };
    }
    case ACTIONS.FETCH_USER_BOOKINGS_FAILED: {
      return { ...state, isFetching: false, error: payload };
    }

    case ACTIONS.UPDATE_USER_BOOKING_INIT: {
      return { ...state, isFetching: true, success: false, error: null };
    }
    case ACTIONS.UPDATE_USER_BOOKING_SUCCEDED: {
      return { ...state, isFetching: false, success: true, user: payload };
    }
    case ACTIONS.UPDATE_USER_BOOKING_FAILED: {
      return { ...state, isFetching: false, success: false, error: payload };
    }

    default: {
      return state;
    }
  }
}
